import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import L from 'leaflet';
import { useMap, useMapEvents } from 'react-leaflet';
import { styled } from '@mui/material/styles';
import {
  Header,
  StationMarker,
  VehicleMarker,
  Spinner,
  PolylineDecorator,
  DirectionArrowMarker,
} from './';
import {
  usePreselectRoute,
  useFetchData,
  useAutoZoom,
  useGetStationIcons,
  useFetchVehicles,
  useGetPolylineDecoratorArrow,
} from '../hooks';
import {
  INITIAL_ZOOM_LEVEL,
  ROUTE_COLOR,
  RS_WAYS,
  SELECTED_CITY_CONFIG,
  VEHICLE_TYPES_ICONS,
} from '../constants';
import tranzyLogo from '../assets/img/tranzy-logo.svg';
import {
  getStationsKeyByWay,
  getCoordinatesKeyByWay,
  getMiddleCoordinateOnRoute,
  getExtendedRoute,
} from '../utils';

const { COLOR: CITY_COLOR } = SELECTED_CITY_CONFIG;

const TranzyLogo = styled('img')`
  width: 100px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  cursor: pointer;
`;

function MapContent() {
  const map = useMap();
  const divRef = useRef(null);

  const { isLoadingGtfsData, gtfsData } = useFetchData();
  const { routes, trips, shapes, stops, stopTimes } = gtfsData;
  const { vehicles, setStartFetchVehicles, resetFetchVehicles } =
    useFetchVehicles(trips);

  const [selectedRoute, setSelectedRoute] = useState(null);
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const [rsWay, setRsWay] = useState(RS_WAYS.TOUR);
  const [userHasChangedRsWay, setUserHasChangedRsWay] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(INITIAL_ZOOM_LEVEL);

  const validRouteId = usePreselectRoute(
    gtfsData,
    setSelectedRoute,
    setAutocompleteValue,
    setStartFetchVehicles,
  );

  const { zoomedStationId, zoomedVehicleName } = useAutoZoom(
    selectedRoute,
    vehicles,
    setRsWay,
    userHasChangedRsWay,
  );
  const { terminalStationIcon, intermediateStationIcon, arrowIcon } =
    useGetStationIcons({
      color: selectedRoute?.route_color || ROUTE_COLOR,
      zoomLevel,
      station1: selectedRoute?.[getStationsKeyByWay(rsWay)][0] ?? null,
      station2: selectedRoute?.[getStationsKeyByWay(rsWay)][1] ?? null,
    });

  // const polylineArrows = useGetPolylineArrows({
  //   bgColor: selectedRoute?.route_color || ROUTE_COLOR,
  //   zoomLevel,
  //   coordinates: selectedRoute?.[getCoordinatesKeyByWay(rsWay)] ?? null,
  //   stations: selectedRoute?.[getStationsKeyByWay(rsWay)] ?? null,
  // });

  const polylineDecoratorPatterns = useGetPolylineDecoratorArrow({
    bgColor: selectedRoute?.route_color || ROUTE_COLOR,
    zoomLevel,
  });

  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoomLevel(mapEvents.getZoom());
    },
  });

  useEffect(() => {
    L.DomEvent.disableClickPropagation(divRef.current);
  }, []);

  const getStationIconByIndex = (index, stations) => {
    if (index === 0 || index === stations.length - 1)
      return terminalStationIcon;

    return intermediateStationIcon;
  };

  const lastStationName = useMemo(() => {
    if (!selectedRoute) return '';

    const _stations = selectedRoute[getStationsKeyByWay(rsWay)];

    return _stations[_stations.length - 1]?.stationName ?? '';
  }, [rsWay, selectedRoute]);

  const onSetSelectedRoute = (route) => {
    if (!route) {
      setSelectedRoute(null);
      setRsWay(RS_WAYS.TOUR);
      resetFetchVehicles();
      return;
    }

    const extendedRoute = getExtendedRoute(
      route,
      trips,
      shapes,
      stops,
      stopTimes,
    );

    setSelectedRoute(extendedRoute);
    setRsWay(RS_WAYS.TOUR);
    setStartFetchVehicles(true);

    const middleCoordinateOnRoute = getMiddleCoordinateOnRoute(
      extendedRoute,
      RS_WAYS.TOUR,
    );
    map.setView(middleCoordinateOnRoute, INITIAL_ZOOM_LEVEL);
  };

  const pathOptions = useMemo(
    () => ({
      weight: 5,
      color: selectedRoute?.route_color || ROUTE_COLOR,
    }),
    [selectedRoute?.route_color],
  );

  const getVehicleMarkerIcon = useCallback((vehicle) => {
    // if (SELECTED_CITY_CONFIG.AGENCY_ID === '1' && vehicle.vehicleID === 419) {
    //   return VEHICLE_TYPES_ICONS['bunny'];
    // }

    if (SELECTED_CITY_CONFIG.AGENCY_ID === '1' && vehicle.vehicleID === 318) {
      return VEHICLE_TYPES_ICONS['coffee'];
    }

    if (SELECTED_CITY_CONFIG.AGENCY_ID === '1' && vehicle.vehicleID === 225) {
      return VEHICLE_TYPES_ICONS['santa'];
    }

    return VEHICLE_TYPES_ICONS[vehicle.vehicleType];
  }, []);

  return (
    <div ref={divRef}>
      {isLoadingGtfsData === null || isLoadingGtfsData === true ? (
        <Spinner />
      ) : (
        <>
          <Header
            cityColor={CITY_COLOR}
            routes={routes}
            autocompleteValue={autocompleteValue}
            setAutocompleteValue={setAutocompleteValue}
            selectedRoute={selectedRoute}
            setSelectedRoute={onSetSelectedRoute}
            rsWay={rsWay}
            setRsWay={(value) => {
              setRsWay(value);
              setUserHasChangedRsWay(true);
            }}
            disableAutocomplete={!!(validRouteId && selectedRoute)}
          />

          <a href="https://tranzy.ai/" target="__blank">
            <TranzyLogo src={tranzyLogo} alt="Tranzy Logo" />
          </a>

          <PolylineDecorator
            patterns={polylineDecoratorPatterns}
            pathOptions={pathOptions}
            positions={
              selectedRoute?.[getCoordinatesKeyByWay(rsWay)]?.map((route) => [
                route.lat,
                route.lng,
              ]) ?? []
            }
          />

          {/* <Polyline
            pathOptions={pathOptions}
            positions={
              selectedRoute?.[getCoordinatesKeyByWay(rsWay)]?.map((route) => [
                route.lat,
                route.lng,
              ]) ?? []
            }
          />

         */}

          {selectedRoute && arrowIcon && (
            <DirectionArrowMarker
              icon={arrowIcon}
              station={selectedRoute[getStationsKeyByWay(rsWay)][0]}
            />
          )}

          {selectedRoute?.[getStationsKeyByWay(rsWay)]?.map(
            (station, index, _stations) => (
              <StationMarker
                key={`${station.stationID}-${index}`}
                station={station}
                icon={getStationIconByIndex(index, _stations)}
                openPopup={zoomedStationId === station.stationID}
              />
            ),
          )}

          {selectedRoute &&
            vehicles
              ?.filter(
                (vehicle) =>
                  vehicle.vehicleRouteID === selectedRoute.route_id &&
                  vehicle.vehicleRouteWay === rsWay,
              )
              .map((vehicle, index) => (
                <VehicleMarker
                  key={`${vehicle.vehicleName}-${index}`}
                  vehicle={vehicle}
                  // icon={VEHICLE_TYPES_ICONS[vehicle.vehicleType]}

                  icon={getVehicleMarkerIcon(vehicle)}
                  lastStationName={lastStationName}
                  openPopup={zoomedVehicleName === vehicle.vehicleName}
                />
              ))}
        </>
      )}
    </div>
  );
}

export default MapContent;
